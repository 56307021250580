import { useState, useEffect }                              from 'react';
import { useAuth }                                          from "contexts/authContext";
import { getAllSiteTenants }                                from 'functions/useTenantFunctions';
import { showErrorToast, showSuccessToast }                 from 'functions/toasts';
import { checkIfEmpty, checkIfNameIsValid, isValidEmail }   from 'functions/functions';
import {CloseCircleOutlined} from '@ant-design/icons';
import useApi                                               from 'hooks/useApi';
import ReturningVisitorModal                                from '../returningVisitorModal/returningVisitorModal';
import CustomModal                                          from 'components/modal/modal';
import TextInput                                            from 'components/inputs/textInput/textInput';
import SelectInput                                          from 'components/inputs/selectInput/selectInput';
import Button                                               from 'components/buttons/button';
import styles                                               from './signInModal.module.scss';
import Skeleton                                             from 'react-loading-skeleton';
import moment                                               from 'moment';



const Body = ({
    signInForm, setSignInForm,
    form, setForm,
    loading, setLoading,
    visitorName, setVisitorName,
    tenants, setTenants,
    tenant, setTenant,
    openReturningVisitorModal,
    searchedVisitor, cancelSearchedVisitor,
    selectedPreRegUser,
    employees,
    showRecognizeOption,
    signInVisitor,
    onBlur,
    error, setError,
    errorMessages, setErrorMessages,
    selectedEmployee,
    onTenantChange,
    onEmployeeSelect
}) => {

    const setFieldValue = (e, index) => {
        const updatedForm = signInForm.fields.map((field, i) => {
            if(i === index) {
                if(field.type !== 'dropdown') {
                    return {
                        ...field,
                        required : field.required === 'true' ? 'true' : 'false',
                        value : e.target.value
                    }
                } else {
                    return {
                        ...field,
                        required : field.required === 'true' ? 'true' : 'false',
                        value : e?.value || ''
                    }
                }
                
            }
            return field;
        });
        setSignInForm(form => ({
            ...form,
            fields : updatedForm
        }));
       
    }



    const onVisitorBlur = (e) => {
        let visitorName = e.target.value;

        let errorObj = {
            ...error,
            visitorName : false
        }

        let errorMessageObj = {
            ...errorMessages,
            visitorName : ''
        }

        // Checking if visitor name is a valid full name
        const {valid, message } = checkIfNameIsValid(visitorName);
        if(checkIfEmpty(visitorName)) {
            errorObj.visitorName = true;
            errorMessageObj.visitorName = 'Visitor Name is mandatory'
        } else if(!valid) {
            errorObj.visitorName = true;
            errorMessageObj.visitorName = message == 'not-full-name' ? 'Please enter full name of visitor' : 'Name can only contain alphabetic characters and spaces'
        }

        setError(errorObj);
        setErrorMessages(errorMessageObj);
    }

    const getDropdownOptions = (field) => {
        if (!Array.isArray(field.dropdownOptions)) {
            return [];
        }
    
        return field.dropdownOptions.map((item) => {
            if (typeof item === 'string') {
                // Convert string to an object with 'label' and 'value'
                return {
                    'label': item,
                    'value': item
                };
            } else if (typeof item === 'object' && item !== null && 'label' in item && 'value' in item) {
                // If it's already an object with 'label' and 'value', return it as is
                return item;
            } else {
                // Handle unexpected item formats (e.g., skip or transform)
                return null;  // or you can choose to skip or handle differently
            }
        }).filter(item => item !== null); // Filter out any nulls from the list
    }

    

    return (
        <>
        {!loading ?
            <>
                {!selectedPreRegUser && showRecognizeOption &&
                <div className='flex flex-col'>
                    <div onClick={openReturningVisitorModal} className='bg-[#EEF7FF] hover:bg-[#deefff] cursor-pointer inline-block px-7 mb-3 text-[1rem] text-[#0761B4] font-medium py-3 rounded-full w-fit'>
                        Returning Visitor ? Sign in visitor with past records
                    </div>
                    {searchedVisitor &&
                    <div className='bg-[#fff5ee] hover:bg-[#ffefe3] w-fit cursor-pointer px-7 flex gap-3 mb-5 text-[1rem] text-[#b66f3c] font-medium py-2 rounded-full'>
                        <div>{searchedVisitor.data.name}</div>
                        <CloseCircleOutlined className='text-xl text-red-400 hover:text-red-600' onClick={cancelSearchedVisitor} />
                    </div>}
                </div>}
                <div className={styles.signInForm}>
                    <TextInput 
                        label="Visitor Name" 
                        placeholder="Enter Visitor Name" 
                        value={visitorName} 
                        onChange={(e) => setVisitorName(e.target.value)} 
                        required
                        onBlur={onVisitorBlur}
                        isError={error.visitorName}
                        errorMessage={errorMessages.visitorName} 
                    />
                    <SelectInput showError={true} isError={error.tenant} errorMessage={errorMessages.tenant} options={tenants} defaultValue={tenant} onChange={onTenantChange} label="Company Visiting" placeholder="Select Company" required />
                    {signInForm?.employees?.enabled === 'true' && employees.length !== 0 && <SelectInput showError={true} isError={error.personVisiting} errorMessage={errorMessages.personVisiting} required={signInForm.employees.required === 'true'}  options={employees} defaultValue={selectedEmployee} onChange={onEmployeeSelect} label={signInForm.employees.label} placeholder={signInForm.employees.label} />}
                    {signInForm && signInForm?.fields?.map((field, index) => {
                        return (
                            field.type === 'string' || field.type === 'email' || field.type === 'numeric' ? 
                            <TextInput 
                                label={field.name} 
                                placeholder={field.label}
                                required={field.required === 'true' ? true : false}
                                type={field.type === 'numeric' ? 'number' : 'text'}
                                value={field.value}
                                onChange={(e) => setFieldValue(e, index)}
                                onBlur={() => onBlur(field)}
                                isError={error[field.name]}
                                errorMessage={errorMessages[field.name]} 
                            /> : field.type === 'dropdown' ? 
                            <SelectInput 
                                label={field.label} 
                                showError={true}
                                isClearable
                                menuPlacement="bottom"
                                defaultValue={field.value ? {label : field.value, value : field.value} : ''}
                                isError={error[field.name]}
                                errorMessage={errorMessages[field.name]}  
                                onChange={(e) => setFieldValue(e, index)} 
                                options={getDropdownOptions(field)} 
                                required={field.required === 'true'} 
                            /> : ''
                        )
                    })}
                </div>
            </>
            : 
                <div>
                    <div>
                        <Skeleton width="20%" />
                        <Skeleton height={40} />
                    </div>
                    <div className="mt-2">
                        <Skeleton width="20%" />
                        <Skeleton height={40} />
                    </div>
                    <div className="mt-2">
                        <Skeleton width="20%" />
                        <Skeleton height={40} />
                    </div>
                </div>
            }
        </>
    )
}

const Footer = ({signInVisitor}) => {
    return (
        <div className={styles.footerContainer}>
            <Button text="Sign In Visitor" onClick={signInVisitor} />
        </div>
    )
}

/**
 * 
 * @param {Boolean} isModalOpen                 - Shows or hides modal
 * @param {Function} closeModal                 - Function to close the modal
 * @param {Function} getCurrentVisitorsData     - Function to load the current visitors data
 */

const SignInModal = ({isModalOpen, closeModal, getCurrentVisitorsData, selectedPreRegUser}) => {

    const [signInForm, setSignInForm]                           = useState([]);
    const [form, setForm]                                       = useState([]);
    const [loading, setLoading]                                 = useState(false);
    const [visitorName, setVisitorName]                         = useState('');
    const [tenant, setTenant]                                   = useState('');
    const [selectedEmployee, setSelectedEmployee]               = useState('');
    const [tenants, setTenants]                                 = useState([]);
    const [employees, setEmployees]                             = useState([]);
    const [error, setError]                                     = useState([]);
    const [errorMessages, setErrorMessages]                     = useState([]);
    const [returnVisitorModal, setReturnVisitorModal]           = useState(false);
    const [searchedVisitor, setSearchedVisitor]                 = useState();
    const [tenantTemplate, setTenantTemplate]                   = useState([]);
    const [showRecognizeOption, setShowRecognizeOption]         = useState(false);

    const {user, currentBuilding} = useAuth();

    const {get, post} = useApi();

    useEffect(() => {
        fetchData();
        checkRecognizeVisitorsEnabled();
    }, []);

    useEffect(() => {
        if(searchedVisitor?.status) setVisitorName(searchedVisitor.data.name);
    },[searchedVisitor]);


    // Fetch All Data Once the Modal is Loaded
    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await get(`buildings/${currentBuilding.attributes.building_id}/tenants`);
            const data = response.data.data;

            let tenantOptions = [];
            data.forEach(tenant => {
                if(tenant.attributes.is_active === 1) {
                    tenantOptions.push({
                        ...tenant.attributes,
                        label : tenant.attributes.name,
                        value : tenant.attributes.tenant_id,
                    })
                }
            });

            setTenants(tenantOptions);

            // If the modal is opened by clicking a pre-registered visitor, then pre-fill the available data.
            if(selectedPreRegUser) {
                setVisitorName(selectedPreRegUser.visitor_name);

                let selectedTenant = tenantOptions.filter((tenant) => tenant.tenant_id === selectedPreRegUser.tenant_id);

                if(selectedPreRegUser.tenant_id) {
                    let employeeOptions = await fetchTenantEmployees(selectedPreRegUser.tenant_id);
                    let selectedEmployee = employeeOptions.filter((employee) => employee.employee_id === selectedPreRegUser.employee_id);
                    setSelectedEmployee(selectedEmployee[0]);
                }
                console.log(selectedPreRegUser)
                setTenant(selectedTenant[0]);
                setForm(selectedPreRegUser.form_data);
                setSignInForm(selectedPreRegUser.form_data);
            }

            setLoading(false);

        } catch(err) {
            showErrorToast('Something Went Wrong');
        }
    }

    const checkRecognizeVisitorsEnabled = async () => {
        try {
            const response = await get(`buildings/${currentBuilding.attributes.building_id}`);
            const data = response.data.data;

            setShowRecognizeOption(data.attributes.settings.recognize_visitors ? true : false);

            setLoading(false);

        } catch(err) {
            console.log(err);
        }
    }

    // When tenant is selected from the dropdown
    const onTenantChange = async (e) => {
        setLoading(true)
        setTenant(e);
        await Promise.all([getTenantTemplate(e.template_id), fetchTenantEmployees(e.tenant_id)])
        setLoading(false);
    }

    // When Employee is Selected
    const onEmployeeSelect = (e) => {
        setSelectedEmployee(e);
    }

    const cancelSearchedVisitor = () => {
        setSearchedVisitor('');
        resetTemplateValues()
    }

    const resetTemplateValues = () => {
        setLoading(true);
        setVisitorName('');
        let formObj = [];
        tenantTemplate?.fields?.forEach((field) => {
            let obj = {
                ...field,
                value : ''
            };
            
            console.log(field);
            console.log(obj)
            if(field.type === 'dropdown') {
                let array = [];
                field.dropdownOptions.forEach((item) => {
                    array.push(item)
                });
                obj['dropdownOptions'] = array;
            }
            formObj.push(obj);
        })
        setForm(tenantTemplate);
        setSignInForm({
            ...tenantTemplate,
            fields : formObj
        })
        setLoading(false)
    }

    // Fetch Template
    const getTenantTemplate = async(templateId) => {
        console.log(searchedVisitor)
        try {
            const res = await get(`form-templates/${templateId}`);
            setTenantTemplate(res.data.data.form);
            let formObj = [];
            res.data.data?.form?.fields?.forEach((field) => {
                let obj = {
                    ...field,
                };
                // Check if searchedVisitor.status is true
                if (searchedVisitor?.status) {
                    const matchingField = searchedVisitor?.data?.fields?.find((item) => item.name === field.name);
                    obj['value'] = matchingField ? matchingField.value : ''; // Assign value or fallback to ''
                } else {
                    obj['value'] = ''; // Default value when searchedVisitor.status is false
                }
                
                if(field.type === 'dropdown') {
                    let array = [];
                    field.dropdownOptions.forEach((item) => {
                        array.push(item)
                    });

                    obj['dropdownOptions'] = array;
                }
                formObj.push(obj);
            })
            setForm(res.data.data.form);
            
            setSignInForm({
                ...res.data.data.form,
                fields : formObj
            })
            
        } catch(err) {
            console.log('ERROR___',err);
            showErrorToast('Something Went Wromg');
        }
    }

    // Fetch Tenant Employees
    const fetchTenantEmployees = async (tenantId) => {
        try {
            const response = await get(`tenants/${tenantId}/employees`);
            const data = response.data.data;

            let employeeOptions = [];

            data.forEach((employee) => {
                if(employee.attributes.is_active === 1) {
                    employeeOptions.push({
                        ...employee.attributes,
                        label : employee.attributes.name,
                        value : employee.attributes.employee_id
                    })
                }
                
            })

            setEmployees(employeeOptions)

            return employeeOptions;

        } catch(err) {
            console.error(console.log('ERROR___',err));
            showErrorToast('Something Went Wrong')
        }
    }

    // Function to open returning visitor modal
    const openReturningVisitorModal = () => {
        setReturnVisitorModal(true);
    }

    // Function to close returning visitor modal
    const closeReturningVisitorModal = () => {
        setReturnVisitorModal(false);
    }

    // Function to sign in thr vistor
    const signInVisitor = async () => {
        setLoading(true);
        
        // Condtion to check if Sign In Form is empty by any chance
        if(signInForm == null || signInForm == undefined || signInForm.length == 0) {
            showErrorToast('There is a problem in signing in. Please reload the page and try again');
            setTimeout(() => {
                setLoading(false);
                closeModal();
            }, 2000)
        } else {
            const now = moment();
            
            let errorObj = {
                visitorName : false,
                personVisiting : false
            };

            let errorMessageObj = {
                visitorName : '',
                personVisiting : ''
            }

            // Checking if visitor name is a valid full name
            const {valid, message } = checkIfNameIsValid(visitorName);
            if(checkIfEmpty(visitorName)) {
                errorObj.visitorName = true;
                errorMessageObj.visitorName = 'Visitor Name is mandatory'
            } else if(!valid) {
                errorObj.visitorName = true;
                errorMessageObj.visitorName = message == 'not-full-name' ? 'Please enter full name of visitor' : 'Name can only contain alphabetic characters and spaces'
            }

            if(!tenant) {
                errorObj.tenant = true;
                errorMessageObj.tenant = 'This field is mandatory'
            }

            if(form?.employees?.enabled === 'true' && form?.employees?.required === 'true' && employees.length > 0) {
                if(!selectedEmployee) {
                    errorObj.personVisiting = true;
                    errorMessageObj.personVisiting = 'This field is mandatory'
                }
            }

            signInForm?.fields?.forEach((field) => {
                errorObj = {
                    ...errorObj,
                    [field.name] : false
                }
        
                errorMessageObj = {
                    ...errorMessageObj,
                    [field.name] : ''
                }
        
                if(field.required === "true") {
                    if(checkIfEmpty(field.value)) {
                        errorObj[field.name] = true;
                        errorMessageObj[field.name] = 'This field is mandatory'
                    }
                }
                
        
                if(!errorObj[field.name] && field.type === 'email' && (field.value != "" && field.value != null)) {
                    if(!isValidEmail(field.value)) {
                        errorObj[field.name] = true;
                        errorMessageObj[field.name] = 'Enter Valid Email Address'
                    }
                }
            })

            const hasError = Object.values(errorObj).some(value => value === true);
            

            if(!hasError) {
                
                let data = {
                    visitor_name        : visitorName,
                    tenant_id           : tenant.tenant_id,
                    form_data           : JSON.stringify(signInForm),
                    sign_in_time        : now.format('Y-MM-DD HH:mm:ss'),
                    is_pre_registered   : selectedPreRegUser ? 1 : 0,
                    pre_registration_id : selectedPreRegUser.pre_registration_id,
                    manual_sign_in      : 1
                };

                // Appending Email Address & Phone Number if they are added in the custom fields for returning visitors function
                signInForm.fields.forEach((field) => {
                    let fieldValue = field.value.trim();
                    if(field.name === 'Phone Number' && fieldValue != '' && fieldValue != null && fieldValue !== undefined && fieldValue !== ' ') {
                        data['phone_number'] = fieldValue;
                    }else if(field.name === 'Email Address' && fieldValue != '' && fieldValue != null && fieldValue !== undefined && fieldValue !== ' ') {
                        data['email_address'] = fieldValue;
                    }
                })

                if(selectedEmployee) {
                    data['employee_id'] = selectedEmployee.employee_id;
                }
        
                try {
                    await post(`buildings/${currentBuilding.attributes.building_id}/sign-in`, data);
                    getCurrentVisitorsData();
                    showSuccessToast('New Visitor Signed In Successfully');
                    setLoading(false)
                    closeModal();
                    
                } catch(err) {
                    showErrorToast('Something Went Wrong');
                }
            }

            setError(errorObj);
            setErrorMessages(errorMessageObj);
            setLoading(false);
        }
        
    }

    const onBlur = (field) => {
        let errorObj = {
            ...error,
            [field.name] : false
        }

        let errorMessageObj = {
            ...errorMessages,
            [field.name] : ''
        }

        if(field.required === "true") {
            if(checkIfEmpty(field.value)) {
                errorObj[field.name] = true;
                errorMessageObj[field.name] = 'This field is mandatory'
            }
        }
        

        if(!errorObj[field.name] && field.type === 'email') {
            if(!isValidEmail(field.value)) {
                errorObj[field.name] = true;
                errorMessageObj[field.name] = 'Enter Valid Email Address'
            }
        }

        setError(errorObj);
        setErrorMessages(errorMessageObj)
    }

    return (
        <>
        <CustomModal isModalOpen={isModalOpen} closeModal={closeModal} width="40%" minHeight="60%">
            {{ 
                header : 'Sign In Visitor',
                body :  <Body 
                            signInForm={signInForm} 
                            setSignInForm={setSignInForm}
                            visitorName={visitorName}
                            setVisitorName={setVisitorName}
                            tenant={tenant}
                            setTenant={setTenant}
                            tenants={tenants}
                            setTenants={setTenants}
                            searchedVisitor={searchedVisitor}
                            cancelSearchedVisitor={cancelSearchedVisitor}
                            employees={employees}
                            onTenantChange={onTenantChange}
                            showRecognizeOption={showRecognizeOption}
                            openReturningVisitorModal={openReturningVisitorModal}
                            signInVisitor={signInVisitor}
                            form={form} setForm={form}
                            selectedPreRegUser={selectedPreRegUser}
                            onBlur={onBlur}
                            error={error} setError={setError}
                            errorMessages={errorMessages} setErrorMessages={setErrorMessages}
                            selectedEmployee={selectedEmployee}
                            onEmployeeSelect={onEmployeeSelect}
                            loading={loading}
                            setLoading={setLoading}
                        />,
                footer : <Footer 
                            signInVisitor={signInVisitor}
                        />
             }}
        </CustomModal>
        {returnVisitorModal &&
            <ReturningVisitorModal 
                returnVisitorModal={returnVisitorModal}
                closeReturningVisitorModal={closeReturningVisitorModal}
                setSearchedVisitor={setSearchedVisitor} 
            />}
        </>
    )
}

export default SignInModal