import { Alert }        from "antd";
import Skeleton         from "react-loading-skeleton";
import DashboardLayout  from "layouts/dashboardLayout/dashboard.jsx";
import SubMenuLayout    from "layouts/subMenuLayout/subMenuLayout";
import RSwitch          from "components/switch/switch";
import Button           from "components/buttons/button";
import styles           from './health&Safety.module.scss';
import useHealthSafety  from "./useRecognizeVisitors";
import CheckPermission  from "components/checkPermission/checkPermission";

const RecognizeVisitorsScreen = () => {

    const {
        loading, setLoading,
        onSaveChanges,
        recognizeVisitors, setRecognizeVisitors
    } = useHealthSafety();


   

  return (
    <DashboardLayout>
        
        <SubMenuLayout subMenuType="site" currentMenu="Recognize Visitors">
            <p className="gb_title">Recognize Visitors</p>
            <p className="gb_subtitle">Enable recognize visitors option to remember the returning visitors and pre-fill the data for them for faster checkout.</p>
            {!loading ?
            <div className={styles.form}>
                <Alert type="warning" showIcon description="Warning: Sign-in forms assigned to each tenant must include either an Email Address or Phone Number field for the visitor recognition feature to function properly." />
                <RSwitch status={recognizeVisitors} onChange={() => setRecognizeVisitors(!recognizeVisitors)} label="Recognize Returning Visitors" />
                <div className="mt-3 gb-flex-end">
                    <CheckPermission permission="sites_add_edit">
                        <Button text="Save Changes" onClick={onSaveChanges} />
                    </CheckPermission>
                </div>
            </div> : 
            <div className="mt-3">
                <div>
                    <Skeleton width="20%" />
                    <Skeleton height={40} />
                </div>
                <div className="mt-2">
                    <Skeleton width="20%" />
                    <Skeleton height={100} />
                </div>
                <div className="mt-2">
                    <Skeleton width="20%" height={30} />
                </div>
                <div className="mt-2">
                    <Skeleton width="20%" height={30} />
                </div>
            </div>
            }
        </SubMenuLayout>
    </DashboardLayout>
  )
}

export default RecognizeVisitorsScreen