import { useState, useEffect, useRef } from "react"
import useApi from "hooks/useApi";
import { useParams }            from "react-router-dom";
import axios                            from "axios";
import { APP_CONFIG } from "constants/config";
import { checkIfEmpty } from "functions/functions";
import { showSuccessToast, showErrorToast } from "functions/toasts";

const useRecognizeVisitors = () => {

    
    const [loading, setLoading]                     = useState(true);
    const [recognizeVisitors, setRecognizeVisitors] = useState(true);

    const {id} = useParams();

    const { get, post } = useApi();


    useEffect(() => {
        fetchRecognizeVisitorData();
    }, []);

    const fetchRecognizeVisitorData = async () => {
        try {
            const response = await get(`buildings/${id}`);
            const data = response.data.data;

            setRecognizeVisitors(data.attributes.settings.recognize_visitors);

            setLoading(false);

        } catch(err) {
            console.log(err);
        }
    }

    const onSaveChanges = async () => {
        setLoading(true);

            try {

                const data = {
                    recognize_visitors : recognizeVisitors ? 1 : 0
                }
                
                await post(`buildings/${id}`, data);
                
                // await fetchRecognizeVisitorData();
                showSuccessToast('Recognize Visitor Status Updated Successfully');

            } catch(err) {
                showErrorToast('Something Went Wrong');
            }

        setLoading(false);

    }

   

    return {
        loading, setLoading,
        recognizeVisitors, setRecognizeVisitors,
        onSaveChanges
    }

}

export default useRecognizeVisitors