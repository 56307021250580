import { useState, useEffect }  from "react";
import { useParams }            from "react-router-dom";
import { showErrorToast }       from "functions/toasts";
import useApi                   from "hooks/useApi";

const useSignInForm  = (props) => {

    const {
      selectedTemplate, 
      mode, 
      availableFields, 
      setAvailableFields, 
      formFields, 
      setFormFields,
      employees, setEmployees, 
      addFieldToSignInForm,
      messageApi
  } = props;

  const [fieldModal, setFieldModal]         = useState(false);
  const [fieldModalMode, setFieldModalMode] = useState('add');
  const [searchValue, setSearchValue]       = useState('');
  const [selectedField, setSelectedField]   = useState({});
  const [addField, setAddField]             = useState('');


    // Handle drag end event for moving and sorting fields
    const onDragEnd = (result) => {
      const { source, destination } = result;

      // If no destination, don't do anything
      if (!destination) return;

      // Handle reordering within the same list
      if (source.droppableId === destination.droppableId) {
        if (source.droppableId === 'availableFields') {
          const reorderedFields = reorder(availableFields, source.index, destination.index);
          setAvailableFields(reorderedFields);
        } else if (source.droppableId === 'formFields') {
          const reorderedFields = reorder(formFields, source.index, destination.index);
          setFormFields(reorderedFields);
        }
      } else {
        // Handle moving between lists
        if (source.droppableId === 'availableFields' && destination.droppableId === 'formFields') {
          // Move from availableFields to formFields
          const draggedField = availableFields[source.index];

          // Add the field to the formFields at the correct position
          const newFormFields = Array.from(formFields);
          newFormFields.splice(destination.index, 0, createFieldForForm(draggedField));
          setFormFields(newFormFields);

          // Remove the dragged field from availableFields
          const newAvailableFields = Array.from(availableFields);
          newAvailableFields.splice(source.index, 1);
          setAvailableFields(newAvailableFields);
        } else if (source.droppableId === 'formFields' && destination.droppableId === 'availableFields') {
          // Move from formFields back to availableFields
          const result = moveWithPosition(formFields, availableFields, source.index, destination.index);
          setFormFields(result.sourceList);
          setAvailableFields(result.destList);
        }
      }
    };

    // Helper function to structure the field before adding it to formFields for drag and drop
    const createFieldForForm = (field) => {
      const newField = {
        id        : field.name,
        name      : field.name,
        label     : field.name,
        required  : "false",
        default   : field.default,
        type      : field.type
      };

      // Add dropdownOptions Property if field type is dropdown
      if (field.type === 'dropdown') {
        newField["dropdownOptions"] = field.dropdownOptions;
      }

      return newField;
    };

    // Reorder items in the same list
    const reorder = (list, startIndex, endIndex) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      return result;
    };

    // Move items between lists and insert them at the right position
    const moveWithPosition = (sourceList, destList, sourceIndex, destIndex) => {
      const sourceClone = Array.from(sourceList);
      const destClone = Array.from(destList);
      const [removed] = sourceClone.splice(sourceIndex, 1);

      // Insert the removed item at the destination index
      destClone.splice(destIndex, 0, removed);

      return {
        sourceList: sourceClone,
        destList: destClone,
      };
    };

    // Log fields in correct order
    const logFormFields = () => {
      console.log('Available Fields:', availableFields);
      console.log('Form Fields:', formFields);
    };

    const openFieldModal = () => {
      setFieldModal(true);
    }

    const closeFieldModal = () => {
      setAddField('');
      setFieldModal(false);
    }

    // When edit button on a field is clicked
    const onEditFieldClicked = (field) => {
      setSelectedField(field);
      setFieldModalMode('edit');
      setFieldModal(true);
    }

    // Function to open modal for editing employees
    const employeeEdit = () => {
      console.log(employees)
      setSelectedField(employees);
      setFieldModalMode('edit');
      setFieldModal(true);
    }

    // Function to open modal for adding new field
    const addNewField = () => {
      setFieldModal(true);
      setFieldModalMode('add');
    }

    const onCreateFieldClick = () => {
      console.log(searchValue);
      setAddField(searchValue);
      addNewField();
    }

    // Function to disable employees
    const disableEmployees = () => {
      setEmployees({
        ...employees,
        enabled : "false"
      })
    }

    const addNewFieldToAvailableFields = (field) => {
      // Adding the newly created field to the available field list
      setAvailableFields([
        {
          id        : field.name,
          name      : field.name,
          label     : field.label,
          required  : "false",
          type      : field.type,
          ...(field.type === 'dropdown' && {dropdownOptions : field.dropdownOptions})
        },
        ...availableFields,
      ]);
      setFieldModal(false);
      messageApi.open({
        type: 'success',
        content: 'Field added successfully',
    })
    }


    // Function to update a field properties
    const updateFieldProperties = (fieldName, fieldLabel, fieldType, fieldRequied, dropdownOptions) => {
      if(selectedField.fieldId === "employees") {
        setEmployees({
          ...employees,
          label : fieldLabel,
          required : fieldRequied ? 'true' : 'false',
        });
        closeFieldModal();
      } else {
        console.log(formFields, selectedField);
          const updatedFormFields = formFields.map((field) => {
            console.log(formFields, selectedField);
              if(field.name === selectedField.name) {
                  return {
                      ...field,
                      label : fieldLabel,
                      type : fieldType,
                      required : fieldRequied ? 'true' : 'false',
                      ...(fieldType === 'dropdown' && {dropdownOptions : dropdownOptions})
                  }
              }
              return field;
          });
          console.log(updatedFormFields)
          setFormFields(updatedFormFields);
          closeFieldModal();
      }
    }

    return {
        onDragEnd,
        reorder,
        moveWithPosition,
        logFormFields,
        openFieldModal,
        closeFieldModal,
        fieldModalMode,
        onEditFieldClicked,
        selectedField,
        addNewField,
        onCreateFieldClick,
        employeeEdit,
        addNewFieldToAvailableFields,
        disableEmployees,
        addField,
        searchValue, setSearchValue,
        updateFieldProperties,
        fieldModal, setFieldModal
    }
}

export default useSignInForm;