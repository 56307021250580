export const FIELD_TYPE_TAG_COLORS = {
    'string'    : 'blue',
    'numeric'   : 'cyan',
    'dropdown'  : 'orange',
    'email'     : 'purple'
};

export const FIELD_TYPE_TAG_LABELS = {
    'string'    : 'Text',
    'numeric'   : 'Number',
    'dropdown'  : 'Dropdown',
    'email'     : 'Email'
}