import { Modal, Input, Select, Alert, Form, Button, Popconfirm, Checkbox, message } from 'antd';
import { FIELD_TYPE_OPTIONS } from './constants';
import {EditOutlined, CheckOutlined, CloseOutlined} from '@ant-design/icons';
import useFieldModal from './useFieldModal';

const FieldModal = ({isModalOpen, closeModal, addField, selectedField, addNewFieldToAvailableFields, updateFieldProperties, fieldModalMode, availableFields}) => {

    console.log(addField);
    message.config({
        top: 100, // Adjust the top position
        right: 20, // Set the right position
        duration: 3, // Optional: Adjust how long the message is shown
        rtl : true
      });

    const [messageApi, contextHolder] = message.useMessage();

    const [form] = Form.useForm();

    const {
        fieldName, setFieldName,
        fieldLabel, setFieldLabel,
        fieldType, setFieldType,
        fieldRequired, setFieldRequired,
        dropdownOptions, setDropdownOptions,
        editingField, setEditingField,
        editingFieldValue, setEditingFieldValue,
        onFieldTypeChange,
        option, setOption,
        addNewOption,
        removeOption,
        updateOption,
        handleFieldNameChange,
        onRequiredChange,
        saveChanges,
        loader, setLoader
    } = useFieldModal(addField, selectedField, fieldModalMode, updateFieldProperties, messageApi, addNewFieldToAvailableFields, availableFields, form);

  return (
    <>
    {contextHolder}
    <Modal 
        title={fieldModalMode == 'edit' ? 'Update Field' : 'Add New Field'} 
        open={isModalOpen} 
        onOk={saveChanges} 
        okText={fieldModalMode == 'edit' ? 'Update Field' : 'Add Field'}
        onCancel={closeModal}
        okButtonProps={{ loading : loader.loading }}
        centered
        width="35%"
        styles={{
            body : {
                maxHeight: '80vh', // Set max-height
                overflowY: 'auto',  // Enable scrolling
                paddingRight: '10px'
            }
        }}
    >
        {fieldModalMode === 'edit' && <Alert closable type='warning' message="Editing field inside template would update the field for this specific template only. No other templates would reflect this change." />}
        <Form
            name="field-form"
            form={form}
            layout='vertical'
            onFinish={saveChanges}
            className='mt-5'
        >
            <Form.Item
                label="Field Name"
                name="fieldName"
                rules={[
                    {
                        required : true,
                        message : 'Please enter field name'
                    }
                ]}
            >
                {/* <label className='text-slate-600 mb-1' for="name">Field Name</label> */}
                <Input disabled={fieldModalMode == 'edit'} id='name' value={fieldName} onChange={handleFieldNameChange} placeholder='Enter Field Name' />
            </Form.Item>
            
            {/* Required Field is only needed for editing field option */}
            {fieldModalMode === 'edit' &&
                <Form.Item
                    name="fieldRequired"
                >
                    <Checkbox checked={fieldRequired} onChange={onRequiredChange} className='mb-3'>Field is Required</Checkbox>
                </Form.Item>
            }
            
            <Form.Item
                label="Display Label"
                name="fieldLabel"
                rules={[
                    {
                        required : true,
                        message : 'Please enter display label'
                    }
                ]}
            >
                {/* <label className='text-slate-600' for="label">Display Label</label> */}
                <Input id='label' onChange={(e) => setFieldLabel(e.target.value)} value={fieldLabel} placeholder='Enter Field Display Label' />
            </Form.Item>

            {selectedField.fieldId !== 'employees' &&
            <Form.Item
                label="Field Type"
                name="fieldType"
                rules={[
                    {
                        required : true,
                        message : 'Please select a field type'
                    }
                ]}
            >
                {/* <label className='text-slate-600' for="type">Field Type</label> */}
                <Select 
                    onChange={onFieldTypeChange} 
                    options={FIELD_TYPE_OPTIONS} 
                    value={FIELD_TYPE_OPTIONS.find(option => option.value === fieldType)} 
                    id="type" 
                    placeholder='Select Field Type' 
                    className='w-full mt-1' 
                />
            </Form.Item>}
            </Form>
            {fieldType == 'dropdown' &&
            <div>
                <h4 className='font-medium'>Dropdown Options</h4>
                <form className='flex gap-2' onSubmit={addNewOption}>
                    <Input placeholder='Enter Option' value={option} onChange={(e) => setOption(e.target.value)} />
                    <Button className='bg-orange-100 text-orange-700' type='primary' onClick={addNewOption}>+ Add</Button>
                </form>
                <div className='flex flex-col gap-2 mt-10'>
                {dropdownOptions.length > 0 ? 
                    dropdownOptions.map((item) => (
                    <>  
                        {editingField !== item &&
                        <div className='bg-white border-[1px] border-slate-200 p-4 rounded-md flex justify-between'>
                            <p className="text-[1.2rem] font-semibold text-slate-500">{item}</p>
                            <div className='flex gap-2'>
                                <Button className='text-slate-400' size='small' icon={<EditOutlined />} onClick={() => {setEditingField(item); setEditingFieldValue(item)}}>Edit</Button>
                                <Popconfirm
                                    title="Delete Option"
                                    description="Are you sure to delete this option from dropdown?"
                                    onConfirm={() => removeOption(item)}
                                    // onCancel={cancel}
                                    okText="Yes, Delete"
                                    cancelText="No"
                                >
                                    <Button danger size='small'>Delete</Button>
                                </Popconfirm>
                            </div>
                        </div> }
                        {editingField === item &&
                        <form onSubmit={(e) => updateOption(e, item, editingFieldValue)} className='bg-slate-50 border-[1px] border-slate-200 p-3 rounded-md flex gap-3 justify-between'>
                            <Input value={editingFieldValue} onChange={(e) => setEditingFieldValue(e.target.value)} />
                            <div className='flex gap-2'>
                                <Button
                                    icon={<CloseOutlined />}
                                    size="middle"  
                                    onClick={() => {setEditingField('')}} 
                                />
                                <Button
                                    type='primary' 
                                    icon={<CheckOutlined />}
                                    size="middle"  
                                    onClick={(e) => {updateOption(e, item, editingFieldValue)}} 
                                />
                            </div>
                        </form> }
                    </>
                )) : 
                    <div className='text-red-600'>No Options Available. Add option to continue</div>
                }
                </div>
            </div> }
        
    </Modal>
    </>
  )
}

export default FieldModal