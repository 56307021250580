import { capitalizeWords }      from "functions/functions";
import { useState, useEffect }  from "react";
import { useParams }            from "react-router-dom"
import { showErrorToast }       from "functions/toasts";
import useApi                   from "hooks/useApi";

const useFieldModal = (addField, selectedField, fieldModalMode, updateFieldProperties, messageApi, addNewFieldToAvailableFields, availableFields, form) => {

    const [editingField, setEditingField]           = useState('');
    const [fieldName, setFieldName]                 = useState('');
    const [fieldLabel, setFieldLabel]               = useState('');
    const [fieldType, setFieldType]                 = useState('');
    const [dropdownOptions, setDropdownOptions]     = useState([]);
    const [option, setOption]                       = useState('');
    const [fieldRequired, setFieldRequired]         = useState(false);
    const [editingFieldValue, setEditingFieldValue] = useState('');
    const [loader, setLoader]                       = useState({
        loading : false
    });

    const {post} = useApi();

    const {id} = useParams();

    useEffect(() => {
        if(fieldModalMode == 'edit') {
            setFieldValues();
        } else {
            if(addField !== '') {
                preFillData();
            }
        }
    },[]);


    // Function to set values from selected field if the mode is edit
    const setFieldValues = () => {
        setFieldName(selectedField.name)
        setFieldLabel(selectedField.label);
        setFieldType(selectedField.type);
        setFieldRequired(selectedField.required == 'true' ? true : false);
        if(selectedField.type == 'dropdown') {
            setDropdownOptions(selectedField.dropdownOptions);
        }
        form.setFieldsValue({
            'fieldName'     : selectedField.name,
            'fieldLabel'    : selectedField.label,
            'fieldType'     : selectedField.type
        })
    }

    const preFillData = () => {
        console.log(addField)
        setFieldName(addField);
        setFieldLabel(`Enter ${addField}`);

        form.setFieldsValue({
            'fieldName'     : addField,
            'fieldLabel'    : `Enter ${addField}`,
        })
    }

    // When field type value is changed
    const onFieldTypeChange = (e) => {
        setFieldType(e);
    }

    const saveChanges = async (e) => {
        e.preventDefault();
        try {

            await form.validateFields();

            if(fieldType === 'dropdown' && dropdownOptions.length === 0) {
                throw 'dropdown-options-not-found'
            }

            if(fieldModalMode == 'add') {
                addNewField();
            } else {
                updateFieldProperties(fieldName, fieldLabel, fieldType, fieldRequired, dropdownOptions);
            }
        } catch(err) {
            console.error('Validation Failed');

            // if(err === 'dropdown-options-not-found')
        }
        
    }

    // Function to add new field to the site
    const addNewField = async () => {
        setLoader(prevLoader => ({
            ...prevLoader,
            loading : true
        }))

        const newFieldName = capitalizeWords(form.getFieldValue('fieldName').trim());

        // Check if the field already exists in the available fields, then prevent adding it again
        let existingField = availableFields?.find(field => field?.name?.toLowerCase() === newFieldName?.toLowerCase());

        if(existingField) {
            form.setFields([
                {
                  name: 'fieldName',
                  errors: ['This field name already exists.'],
                },
              ]);
        } else {
            try {

                // Since values are set manually using form.setFieldValue for adding new field, the values needs to be accessed using getFieldValue
                // Check handleFieldNameChange()
                const data = {
                    name    : newFieldName,
                    type    : form.getFieldValue('fieldType'),
                    label   : form.getFieldValue('fieldLabel').trim()
                }
        
                if(fieldType === 'dropdown') {
                    data['dropdownOptions'] = dropdownOptions;
                }

                await post(`buildings/${id}/fields`, data);
                
                // Function to add the newly created field to the available fields
                addNewFieldToAvailableFields(data);

            } catch(err) {
                showErrorToast('Something Went Wrong');
            } finally {
                setLoader(prevLoader => ({
                    ...prevLoader,
                    loading : false
                }))
            }
        }

        setLoader(prevLoader => ({
            ...prevLoader,
            loading : false
        }))
       
    }

    // When field name is changed, add the same text to field label as well to make easy form filling
    const handleFieldNameChange = (e) => {
        const value = e.target.value;
        form.setFieldsValue({
            'fieldName'     : value,
            'fieldLabel'    : `Enter ${value}`,
        })
    }

    // When field required value is changed
    const onRequiredChange = (e) => {
        console.log(e.target.checked)
        setFieldRequired(e.target.checked);
    }

    // Function to add a new option in dropdown
    const addNewOption = (e) => {
        e.preventDefault();
        
        if(option) {
            // Check if the option already exists (case-insensitive)
            const optionExists = dropdownOptions.some(
                (existingOption) => existingOption.toLowerCase() === option.toLowerCase()
            );

            if (optionExists) {
                messageApi.open({
                    type: 'error',
                    content: 'Option exists already',
                })
                return; // Prevent adding the duplicate option
            }
            const newDropdownOptions = [capitalizeWords(option), ...dropdownOptions];
            setDropdownOptions(newDropdownOptions);
            setOption('');
            messageApi.open({
                type: 'success',
                content: 'Option added!',
            })
        } else {
            messageApi.open({
                type: 'error',
                content: 'Please enter option value',
            })
        }
    }

    // Function to delete an option from dropdown
    const removeOption = (option) => {
        const newDropdownOptions = dropdownOptions.filter((item) => item !== option);
        setDropdownOptions(newDropdownOptions);
        messageApi.open({
            type: 'success',
            content: 'Option removed!',
        })
    }

    // Function to update an option value
    const updateOption = (e, option, newValue) => {
        e.preventDefault();
        const newDropdownOptions = dropdownOptions.map((item) => {
            if(item === option) {
                return newValue;
            } else return item;
        });

        setDropdownOptions(newDropdownOptions);
        setEditingField('');
    }


    return {
        fieldName, setFieldName,
        fieldLabel, setFieldLabel,
        fieldType, setFieldType,
        fieldRequired, setFieldRequired,
        dropdownOptions, setDropdownOptions,
        editingField, setEditingField,
        onFieldTypeChange,
        onRequiredChange,
        addNewOption,
        removeOption,
        option, setOption,
        handleFieldNameChange,
        editingFieldValue, setEditingFieldValue,
        saveChanges,
        updateOption,
        loader, setLoader
    }
}

export default useFieldModal;