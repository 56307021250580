import React, {useRef} from 'react';
import { Form, Modal, Input, Select, Alert, Skeleton } from 'antd';
import ConfirmTenantsChange from './confirmTenantsChange/confirmTenantsChange';

import useTemplateModal from './useTemplateModal';
import SignInForm from './signInForm/signInForm';

const TemplateModal = ({ isModalOpen, closeModal, mode, fetchTemplates, selectedTemplate, templateType, tenantId }) => {
    const formRef = useRef(null);
    const modalBodyRef = useRef(null);
    const [form] = Form.useForm();

    const {
        availableFields, setAvailableFields,
        formFields, setFormFields,
        employees, setEmployees,
        visitorPhoto, setVisitorPhoto,
        tenantsList,
        enableEmployees,
        selectedTenant, setSelectedTenant,
        templateName, setTemplateName,
        addFieldToSignInForm, loader,
        removeFieldFromSignInForm,
        closeTemplateModal,
        saveChanges,
        tenantsAssignment,
        assignedTenantsList, setAssignedTenantsList,
        checkedTenants, setCheckedTenants,
        confirmChangesModal, setConfirmChangesModal,
        onConfirmChanges
    } = useTemplateModal(mode, selectedTemplate, templateType, closeModal, tenantId, form, fetchTemplates, formRef, modalBodyRef);


    return (
        <>
            <Modal
                title="Edit Sign In Form"
                centered
                open={isModalOpen}
                onOk={saveChanges}
                okText="Save Changes"
                okButtonProps={{ loading : loader.saving }}
                maskClosable={false}
                onCancel={closeTemplateModal}
                styles={{
                    body : {
                        maxHeight: '70vh', // Set max-height
                        overflowY: 'auto',  // Enable scrolling
                        padding: '20px',
                    }
                }}
                width="70%"
            >
                <div ref={modalBodyRef} style={{ paddingTop: '20px' }}>
                    {selectedTemplate.default_template ? <Alert type='warning' className='mb-5' showIcon message="This is a pre-defined default template and changes to this template are not allowed. Please create a new template for a different sign in form." /> : '' }
                    <Form 
                        form={form} 
                        name="template-form" 
                        onFinish={saveChanges} 
                        className='flex gap-3 max-w-[40%] mb-10'
                    >
                        <Form.Item 
                            name="templateName"
                            className='flex-[2]'
                            rules={[
                                {
                                    required : true,
                                    message : 'Please enter template name'
                                }
                            ]}
                        >
                            <Input
                                placeholder='Enter template name'
                                value={templateName}
                                disabled={selectedTemplate.default_template}
                                onChange={(e) => setTemplateName(e.target.value)}
                            />
                        </Form.Item>

                        {templateType == 'tenant' &&
                        <Form.Item 
                            name="tenant"
                            className='flex-1'
                            rules={[
                                {
                                    required : true,
                                    message : 'Please select a tenant'
                                }
                            ]}
                        >
                            <Select
                                placeholder     = 'Select tenant'
                                value           = {selectedTenant}
                                options         = {tenantsList}
                                loading         = {loader.tenants}
                                onChange        = {(e) => setSelectedTenant(e)}
                                disabled        = {mode ==='edit' || tenantId} 
                            />
                        </Form.Item>
                        }
                    </Form >
                    <SignInForm
                        availableFields             = {availableFields}
                        setAvailableFields          = {setAvailableFields}
                        formFields                  = {formFields}
                        setFormFields               = {setFormFields}
                        employees                   = {employees}
                        setEmployees                = {setEmployees}
                        enableEmployees             = {enableEmployees}
                        visitorPhoto                = {visitorPhoto}
                        setVisitorPhoto             = {setVisitorPhoto}
                        removeFieldFromSignInForm   = {removeFieldFromSignInForm} 
                        selectedTemplate            = {selectedTemplate}
                        mode                        = {mode}
                        templateType                = {templateType}
                        tenantId                    = {selectedTenant}
                        formRef                     = {formRef}
                        addFieldToSignInForm        = {addFieldToSignInForm}
                        loader                      = {loader}
                        assignedTenantsList         = {assignedTenantsList}
                        setAssignedTenantsList      = {setAssignedTenantsList}
                        checkedTenants              = {checkedTenants}
                        setCheckedTenants           = {setCheckedTenants}
                    />
                </div>
            </Modal>

            {confirmChangesModal && 
            <ConfirmTenantsChange 
                isModalOpen         = {confirmChangesModal}
                closeModal          = {() => setConfirmChangesModal(false)}
                tenantsAssignment   = {tenantsAssignment}
                assignedTenantsList = {assignedTenantsList}
                onConfirmChanges    = {onConfirmChanges}
                templateName        = {templateName}
            />}
        </>
    )
}

export default TemplateModal