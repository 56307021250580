import { useState, useEffect } from "react";
import useTenantFunctions from "functions/useTenantFunctions";
import { useParams }            from "react-router-dom";
import { showErrorToast } from "functions/toasts";

const useAssignedTenants = (selectedTemplate, mode, assignedTenantsList, setAssignedTenantsList, checkedTenants, setCheckedTenants, templateType, tenantId) => {

    const { getAllTenants } = useTenantFunctions();
    const {id} = useParams();

    useEffect(() => {
        fetchData();
    },[]);


    // Fetch Tenants Data
    const fetchData = async () => {
        try {
            const tenantsList = await getAllTenants(id);
            let tenantsArray = [];
            let tenantsCheckedArray = [];

            tenantsList.map((tenant) => {
                tenantsArray.push({
                    label               : tenant.attributes.name,
                    value               : tenant.attributes.tenant_id,
                    template_id         : tenant.attributes.template_id,
                    template_name       : tenant.attributes.template_name,
                    disabled            : (templateType === 'tenant' && tenant.attributes.tenant_id !== tenantId) || (selectedTemplate.template_id === 1 && tenant.attributes.template_id === selectedTemplate.template_id)
                });

                if(mode === 'edit') {
                    if(selectedTemplate.template_id === tenant.attributes.template_id) tenantsCheckedArray.push(tenant.attributes.tenant_id);
                }
            });

            // Sort tenantsArray to have checked tenants first and others in alphabetical order
            tenantsArray.sort((a, b) => {
                // Prioritize checked tenants first
                const isCheckedA = tenantsCheckedArray.includes(a.value) ? -1 : 1;
                const isCheckedB = tenantsCheckedArray.includes(b.value) ? -1 : 1;
        
                // If both tenants have the same check status, sort alphabetically by label
                if (isCheckedA === isCheckedB) {
                    return a.label.localeCompare(b.label);
                }
                return isCheckedA - isCheckedB;
            });


            setAssignedTenantsList(tenantsArray);
            setCheckedTenants(tenantsCheckedArray);

        } catch(err) {
            showErrorToast('Something Went Wrong');
        }
        
    }

    // Handle checkbox change
    const onTenantChange = (checkedValues) => {
        setCheckedTenants(checkedValues);
    };

    return {
        onTenantChange
    }
}

export default useAssignedTenants;