import React, {useState} from 'react'
import { Card, Collapse, Checkbox, Alert } from 'antd'
import useAssignedTenants from './useAssignedTenants';

const { Panel } = Collapse;

const AssignedTenants = ({
    selectedTemplate,
    mode,
    templateType,
    tenantId,
    assignedTenantsList, setAssignedTenantsList,
    checkedTenants, setCheckedTenants,
}) => {

    const {
        onTenantChange
    } = useAssignedTenants(selectedTemplate, mode, assignedTenantsList, setAssignedTenantsList, checkedTenants, setCheckedTenants, templateType, tenantId);


    return (

        <Collapse className='mt-10'>
            {/* Panel with the list of tenants */}
            <Panel header="Assigned Tenants" key="1">

            {mode == 'edit' &&
            <Alert className='mb-5' type='warning' message="Please note that unticking the tenants already assigned to this template would automatically assign them to the Default Template." /> }

            {/* Checkbox Group */}
            <Checkbox.Group
                options={assignedTenantsList}
                value={checkedTenants}
                onChange={onTenantChange}
            />
            </Panel>
        </Collapse>
    )
}

export default AssignedTenants