import { useState, useEffect } from "react";
import useApi from "hooks/useApi";
import { useParams }            from "react-router-dom";
import { showErrorToast, showSuccessToast } from "functions/toasts";
import { checkIfEmpty } from "functions/functions";
import { useChannel, useAbly } from 'ably/react';

const useDevices = () => {

    const [devices, setDevices] = useState([]);
    const [addNewDeviceModalStatus, setAddNewDeviceModalStatus] = useState(false);
    const [deleteDeviceModalStatus, setDeleteDeviceModalStatus] = useState(false);
    const [editDeviceModalStatus, setEditDeviceModalStatus] = useState(false);
    const [selectedDevice, setSelectedDevice] = useState('');
    const [selectedEditDevice, setSelectedEditDevice] = useState('');
    const [deviceName, setDeviceName] = useState('');
    const [loading, setLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [editError, setEditError] = useState('');
    const [editErrorMessages, setEditErrorMessages] = useState('');
    const [editLoading, setEditLoading] = useState(false);
    const [isDeviceConnected, setIsDeviceConnected] = useState(false);
    const [connectedDevices, setConnectedDevices] = useState([]);

    const {id} = useParams();

    const ably = useAbly();

    const presenceChannelName = `presence-device_status/${id}`;
    const checkPresenceChannel = `check-presence/${id}`;

    const { get, del, post } = useApi();

    useEffect(() => {
        const presenceChannel = ably.channels.get(presenceChannelName);
        const checkPresenceChannelObj = ably.channels.get(checkPresenceChannel);
    
        // Subscribe to presence channel for device connection updates
        presenceChannel.presence.subscribe('enter', (member) => {
          // A device entered the channel
          setConnectedDevices(prevDevices => [...prevDevices, member.clientId]);
          console.log(`Device joined: ${member.clientId}`);
          const newDevices = [...connectedDevices, member.clientId];
          console.log(newDevices)
        });
    
        presenceChannel.presence.subscribe('leave', (member) => {
          // A device left the channel
          setConnectedDevices(prevDevices => prevDevices.filter(device => device !== member.clientId));
          console.log(`Device left: ${member.clientId}`);
        });
    
        // Subscribe to the check-presence channel for triggering active/inactive events
        checkPresenceChannelObj.subscribe((message) => {
          if (message.name === 'check-devices') {
            if (message.data === 'trigger-active') {
              // Join the presence channel with buildingId as clientId
              presenceChannel.presence.enter({ clientId: id }).then(() => {
                setIsDeviceConnected(true);
                console.log(`Device joined presence channel: ${presenceChannelName}`);
              }).catch((err) => {
                console.error('Failed to join presence channel:', err);
              });
            } else if (message.data === 'trigger-inactive') {
              // Leave the presence channel
              presenceChannel.presence.leave({ clientId: id }).then(() => {
                setIsDeviceConnected(false);
                console.log(`Device left presence channel: ${presenceChannelName}`);
              }).catch((err) => {
                console.error('Failed to leave presence channel:', err);
              });
            }
          }
        });
    
        // Trigger active event on mount (when DevicesScreen is opened)
        checkPresenceChannelObj.publish('check-devices', 'trigger-active');
    
        // Get the initial list of connected devices on mount
        presenceChannel.presence.get().then((members) => {
          const devices = members.map(member => member.clientId); // Extract clientIds of devices
          setConnectedDevices(devices); // Update the list of connected devices
          console.log('Initial connected devices:', devices);
        }).catch((err) => {
          console.error('Failed to fetch presence members:', err);
        });
    
        // Cleanup when the component unmounts (trigger inactive event)
        return () => {
          checkPresenceChannelObj.publish('check-devices', 'trigger-inactive');
        };
      }, [id, ably]);
    

    useEffect(() => {
        fetchDevicesData();
    }, [])

    const fetchDevicesData = async() => {
        setLoading(true);
        try {
            const response = await get(`buildings/${id}/devices`);
            setDevices(response.data.data);
            setLoading(false);
        } catch(err) {
            console.log(err)
        }
    }

    const deleteDevice = async (id) => {
        setDeleteLoading(true);

        try {
            await del(`devices/${id}`);
            fetchDevicesData();
            showSuccessToast('Device Deleted Successfully');
            setDeleteLoading(false);
            setDeleteDeviceModalStatus(false);

        } catch(err) {
            console.log(err);
            showErrorToast('Something Went Wrong');
        }
    }

    const editDevice = async (id) => {
        setEditLoading(true);

        let trimmedDeviceName = deviceName.trim();

        let errorObj = {
            name : false
        }

        let errorMessageObj = {
            name : ''
        }


        if(checkIfEmpty(trimmedDeviceName)) {
            errorObj.name = true;
            errorMessageObj.name = 'Device Name is Mandatory'
        }

        if(!errorObj.name) {
            try {
                const response = await post(`devices/${id}`, {
                    name : deviceName
                });
    
                console.log(response);
                await fetchDevicesData();
                showSuccessToast('Device Updated Successfully');
                setEditLoading(false);
                setEditDeviceModalStatus(false);
    
            } catch(err) {
                console.log(err);
                showErrorToast('Something Went Wrong')
            }
        }

        setEditError(errorObj);
        setEditErrorMessages(errorMessageObj);

        setEditLoading(false);
    }

    return {

        devices, setDevices,
        addNewDeviceModalStatus, setAddNewDeviceModalStatus,
        deleteDeviceModalStatus, setDeleteDeviceModalStatus,
        editDeviceModalStatus, setEditDeviceModalStatus,
        selectedDevice, setSelectedDevice,
        selectedEditDevice, setSelectedEditDevice,
        deviceName, setDeviceName,
        id,
        deleteDevice,
        fetchDevicesData,
        editDevice,
        editError, editErrorMessages,
        loading, setLoading,
        deleteLoading, setDeleteLoading,
        connectedDevices

    }
}

export default useDevices;
