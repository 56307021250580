import { Modal, Alert, Tag } from "antd"
import {ArrowRightOutlined} from "@ant-design/icons"

const ConfirmTenantsChange = ({isModalOpen, closeModal, tenantsAssignment, assignedTenantsList, onConfirmChanges, templateName}) => {
  return (
    <Modal
        title="Tenant Template Changes"
        open={isModalOpen}
        okText="Confirm"
        onOk={onConfirmChanges}
        onCancel={closeModal}
        styles={{
            body : {
                maxHeight: '70vh', // Set max-height
                overflowY: 'auto',  // Enable scrolling
            }
        }}
        width="30%"
    >
        <Alert message="By confirming, below changes would be affected for the tenants. Please review before confirming" type="warning" showIcon className="mt-2" />
        <div className="mt-5 flex flex-col gap-2">
            {tenantsAssignment?.defaultTemplateAssignment?.length > 0 && tenantsAssignment?.defaultTemplateAssignment?.map((tenant) => {
                return (
                    <div className="bg-slate-50 p-4">
                        <h4 className="font-medium text-slate-700 leading-none">{assignedTenantsList.find((item) => item.value === tenant).label}</h4>
                        <div className="flex gap-1 mt-3">
                            <Tag color="volcano">{assignedTenantsList.find((item) => item.value === tenant).template_name}</Tag>
                            <ArrowRightOutlined className="text-slate-500" />
                            <Tag color="cyan" className="ml-2">Default Template</Tag>
                        </div>
                    </div>
                )
            })}
            {tenantsAssignment?.newAssignment?.tenants?.length > 0 && tenantsAssignment?.newAssignment?.tenants.map((tenant) => {
                return (
                    <div className="bg-slate-50 p-4">
                        <h4 className="font-medium text-slate-700 leading-none">{assignedTenantsList.find((item) => item.value === tenant).label}</h4>
                        <div className="flex gap-1 mt-3">
                            <Tag color="volcano">{assignedTenantsList.find((item) => item.value === tenant).template_name}</Tag>
                            <ArrowRightOutlined className="text-slate-500" />
                            <Tag color="blue" className="ml-2">{templateName}</Tag>
                        </div>
                    </div>
                )
            })}
        </div>
    </Modal>
  )
}

export default ConfirmTenantsChange