export const FIELD_TYPE_OPTIONS = [
    {
        label : 'Text',
        value : 'string'
    },
    {
        label : 'Number',
        value : 'numeric'
    },
    {
        label : 'Email',
        value : 'email'
    },
    {
        label : 'Dropdown',
        value : 'dropdown'
    }
];