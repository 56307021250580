import { useState } from "react";
import DashboardLayout  from "layouts/dashboardLayout/dashboard.jsx"
import { useParams }            from "react-router-dom";
import SubMenuLayout    from "layouts/subMenuLayout/subMenuLayout";
import moment from "moment/moment";
import PageHeader       from "components/pageHeader/pageHeader";
import Icon             from "components/icon/icon";
import { IoIosCheckmark } from "react-icons/io";
import {HiPencil}       from 'react-icons/hi2'
import {BsTrashFill}    from 'react-icons/bs';
import AddNewDeviceModal from "components/modal/addNewDeviceModal/addNewDeviceModal";
import DeleteDeviceModal from "components/modal/deleteDeviceModal/deleteDeviceModal";
import EditDeviceModal  from "components/modal/editDeviceModal/editDeviceModal";
import useDevices       from "./useDevices";
import styles           from './devices.module.scss';
import CheckPermission from "components/checkPermission/checkPermission";


const DevicesScreen = () => {
    const {
        devices,
        addNewDeviceModalStatus, setAddNewDeviceModalStatus,
        deleteDeviceModalStatus, setDeleteDeviceModalStatus,
        editDeviceModalStatus, setEditDeviceModalStatus,
        selectedDevice, setSelectedDevice,
        selectedEditDevice, setSelectedEditDevice,
        deviceName, setDeviceName,
        deleteDevice,
        editDevice,
        fetchDevicesData,
        editError, editErrorMessages,
        loading,
        deleteLoading, setDeleteLoading,
        connectedDevices
    } = useDevices();

    const moment = require('moment');
    const {id} = useParams();

    const deleteDeviceConfirmation = (id) => {
        setSelectedDevice(id);
        setDeleteDeviceModalStatus(true);
    }

    const editDeviceModal = (device) => {
        setSelectedEditDevice(device.device_id)
        setDeviceName(device.name)
        setEditDeviceModalStatus(true);
    }

    const closeModal = () => {
        setAddNewDeviceModalStatus(false);
    }

  return (
    <>
    <DashboardLayout>
        <SubMenuLayout subMenuType="site" currentMenu="Devices">
            <p className="gb_title">Devices</p>
            <p className="gb_subtitle">Manage and connect new devices in the site.</p>
            <div className={styles.devicesContainer}>
                {devices.map((device) => {
                    console.log(device)
                    return (
                        <div className={styles.device}>
                            <div className={styles.deviceInfo}>
                                <h4 className={styles.deviceName}>{device.name}</h4>
                                <p className={`${styles.status} ${connectedDevices.includes(device.device_number) ? 'bg-green-600' : 'bg-red-600'}`}>Status : {connectedDevices.includes(device.device_number) ? 'Connected' : 'Disconnected'}</p>
                                <p className={styles.info}>Added On : {moment(device.created_at).format("MMM Do YYYY")}</p>
                                <p className={styles.info}>OS : {device.os}</p>
                            </div>
                            <CheckPermission permission="sites_add_edit">
                            <div className={styles.bottom}>
                                <div className={styles.row} onClick={() => editDeviceModal(device)}>
                                    <Icon icon={<HiPencil />} />
                                    <p>Edit Device</p>
                                </div>
                                <div className={styles.row} onClick={() => deleteDeviceConfirmation(device.device_id)}>
                                    <Icon icon={<BsTrashFill />} />
                                    <p>Unpair Device</p>
                                </div>
                            </div>
                            </CheckPermission>
                        </div>
                    )
                })}
                <CheckPermission permission="sites_add_edit">
                    <div className={`${styles.device} ${styles.addNew}`} onClick={() => setAddNewDeviceModalStatus(true)}>
                        <div className={`${styles.deviceInfo}`}>
                            <h4 className={styles.addNewDevice}>+ Add New Device</h4>
                        </div>
                    </div>
                </CheckPermission>
            </div>
        </SubMenuLayout>
    </DashboardLayout>
    {addNewDeviceModalStatus && <AddNewDeviceModal status={addNewDeviceModalStatus} fetchDevicesData={fetchDevicesData} closeModal={closeModal} buildingId={id} /> }
    {deleteDeviceModalStatus && <DeleteDeviceModal 
            isModalOpen={deleteDeviceModalStatus} 
            closeModal={() => setDeleteDeviceModalStatus(false)} 
            deleteDevice={() => deleteDevice(selectedDevice)}
            loading={deleteLoading} 
        /> 
    }
    {editDeviceModalStatus && <EditDeviceModal 
            isModalOpen={editDeviceModalStatus}
            closeModal={() => setEditDeviceModalStatus(false)}
            editDevice={() => editDevice(selectedEditDevice)}
            deviceName={deviceName}
            setDeviceName={setDeviceName}
            editError={editError}
            editErrorMessages={editErrorMessages}
        />
    }
    </>
  )
}

export default DevicesScreen