import React from 'react'
import { Input, Modal, Button } from 'antd';
import {SearchOutlined} from '@ant-design/icons';
import useReturningVisitorModal from './useReturningVisitorModal';

const ReturningVisitorModal = ({returnVisitorModal, closeReturningVisitorModal, setSearchedVisitor}) => {

    const {
        searchValue, setSearchValue,
        searchVisitor,
        visitor,
        signInClick,
        loader
    } = useReturningVisitorModal(setSearchedVisitor, closeReturningVisitorModal);

  return (
    <Modal 
        title="Returning Visitor" 
        open={returnVisitorModal} 
        //onOk={handleOk} 
        footer={null}
        onCancel={closeReturningVisitorModal}
    >
        <p className='text-[1rem]'>Enter the email address or phone number used by visitor to sign in on their previous visit.</p>
        <div className='mt-8'>
            <label for="email">Email Address / Phone Number</label>
            <form onSubmit={searchVisitor} className='flex gap-2 mt-1'>
                <Input allowClear id="email" value={searchValue} onChange={(e) => setSearchValue(e.target.value)} placeholder='Enter email address or phone number' />
                <Button loading={loader} icon={<SearchOutlined />} type='primary' onClick={searchVisitor}>Search Visitor</Button>
            </form>
        </div>
        {visitor &&
        <>  
            {visitor.status ?
            <div className='mt-5 bg-slate-50 p-4 rounded flex justify-between'>
                <div>
                    <p className='text-[1.1rem] leading-none'>Great! We have found visitor data for</p>
                    <p className='text-[1.35rem] leading-none font-bold text-primary'>{visitor.data.name}</p>
                </div>
                <div>
                    <Button className='text-slate-900 font-medium' onClick={signInClick}>Sign In</Button>
                </div>
            </div> :
            <div className='mt-5 bg-slate-50 p-4 rounded flex justify-between'>
                <div>
                    <p className='text-[1.1rem] leading-none'>Sorry! We didn't find any records with the given details.</p>
                </div>
            </div> }
        </>}
        <div className='mb-10' />
    </Modal>
  )
}

export default ReturningVisitorModal