import { useState, useEffect }             from "react";
import { useParams }            from "react-router-dom";
import { showErrorToast, showSuccessToast }       from "functions/toasts";
import scrollIntoView from 'scroll-into-view-if-needed';
import useApi                   from "hooks/useApi";
import _ from 'lodash';

const useTemplateModal = (mode, selectedTemplate, templateType, closeModal, tenantId, form, fetchTemplates, formRef, modalBodyRef) => {
    const [availableFields, setAvailableFields] = useState([]);
    const [formFields, setFormFields]           = useState([]);
    const [formFieldsMock, setFormFieldsMock]           = useState([]);
    const [templateName, setTemplateName]       = useState('');
    const [employees, setEmployees]             = useState({
        name : 'Employees',
        label : 'Person Visiting',
        enabled : 'false',
        fieldId : 'employees',
        required : 'false'
    });
    const [employeesMock, setEmployeesMock]             = useState({
        name : 'Employees',
        label : 'Person Visiting',
        enabled : 'false',
        fieldId : 'employees',
        required : 'false'
    });
    const [visitorPhoto, setVisitorPhoto]       = useState(false);
    const [visitorPhotoMock, setVisitorPhotoMock]       = useState(false);
    const [tenantsList, setTenanatsList]        = useState([]);
    const [selectedTenant, setSelectedTenant]   = useState();
    const [loader, setLoader]                   = useState({
        loader      : false,
        fields      : true,
        tenants     : true,
        saving      : false,
        template    : false
    });
    const [assignedTenantsList, setAssignedTenantsList] = useState([]);
    const [checkedTenants, setCheckedTenants] = useState([]);
    const [tenantsAssignment, setTenantsAssignment] = useState();
    const [confirmChangesModal, setConfirmChangesModal] = useState(false);

    const {id} = useParams();
    const {get, post} = useApi();

    useEffect(() => {
        fetchData();
    },[]);


    const fetchData = async () => {
        // Function to fetch available fields of the site
        await fetchSiteFields();

        // Check if modal is opened for editing template, If so, pre-fill the data
        if(mode == 'edit') {
            setTemplateData();
        }

        // Check if the template opened is a tenant template, If so, prepapre dropdown options for tenants
        if(templateType === 'tenant') {
            setTenantsDropdownOptions();
        } else {
            // If template type is global, we need to switch the tenant loader OFF
            setLoader(prevLoader => ({
                ...prevLoader,
                tenants : false
            }));
        }
    }

    // Function to get all site fields
    const fetchSiteFields = async () => {
        setLoader(prevLoader => ({
            ...prevLoader,
            fields : true
        }));

        try {

            const response = await get(`buildings/${id}/fields`);
            const data = response.data.data;

            let fieldsData = [];

            fieldsData = data.map((item) => ({
                id : item.name,
                default : item.default,
                label : item.label,
                name : item.name,
                type : item.type,
                ...(item.type === 'dropdown' && { dropdownOptions: JSON.parse(item.options) || [] })
            }))

            setAvailableFields(fieldsData);

        } catch(err) {
            showErrorToast('Something Went Wrong');
        }

        setLoader(prevLoader => ({
            ...prevLoader,
            fields : false
        }));

    }

    // Function to set Tenants Dropdown Options if template type is tenant
    const setTenantsDropdownOptions = async () => {
        setLoader(prevLoader => ({
            ...prevLoader,
            tenants : true
        }));

        try {
            const data = await fetchTenants();
            let tenantsList = [];

            data.forEach((tenant) => {
                // Add the tenant to the list if tenant is active
                if(tenant.attributes.is_active) {
                    tenantsList.push({
                        label : tenant.attributes.name,
                        value : tenant.attributes.tenant_id,
                    })
                }
            });

            setTenanatsList(tenantsList);

            let selectedTenantOption;

            if(selectedTemplate?.tenant || tenantId) {
                data.forEach((tenant) => {
                    if(selectedTemplate?.tenant) {
                        if((tenant.attributes.tenant_id === selectedTemplate.tenant.attributes.tenant_id) ) {
                            selectedTenantOption = tenant.attributes
                        }
                    } else {
                        if(tenant.attributes.tenant_id == tenantId) {
                            selectedTenantOption = tenant.attributes
                        }
                    }
                    
                });

                setSelectedTenant(selectedTenantOption.tenant_id);
                form.setFieldsValue({
                    'tenant' : selectedTenantOption.tenant_id
                })
            }


        } catch(err) {
            showErrorToast('Something Went Wrong');
        }

        setLoader(prevLoader => ({
            ...prevLoader,
            tenants : false
        }));
    }

    // Fetch all tenants of the site to load in the dropdown
    const fetchTenants = async () => {
        try {
            const response = await get(`buildings/${id}/tenants`);
            const data = response.data.data;

            return data;
            
        } catch(err) {
            showErrorToast('Something Went Wrong');
        }
    }

    // Function to set the selcted template if edit mode
    const setTemplateData = () => {
        setLoader(prevLoader => ({
            ...prevLoader,
            template : true
        }));
        setEmployees(selectedTemplate.form.employees);
        setEmployeesMock(selectedTemplate.form.employees);
        setTemplateName(selectedTemplate.name);
        setVisitorPhoto(selectedTemplate.form.visitorPhoto.required == 'true' ? true : false);
        setVisitorPhotoMock(selectedTemplate.form.visitorPhoto.required == 'true' ? true : false);

        let fieldsList = [];
        selectedTemplate.form?.fields?.map((field, index) => {
            fieldsList.push({
                fieldId : `item-${index}`,
                name : field.name,
                label : field.label,
                type : field.type,
                id : field.name,
                required : field.required,
                ...(field.type === 'dropdown' && {dropdownOptions : field.dropdownOptions})
            })
        })

        setFormFields(fieldsList);
        setFormFieldsMock(fieldsList);

        form.setFieldsValue({
            'templateName' : selectedTemplate.name
        })

        filterAvailableFields(fieldsList);
        setLoader(prevLoader => ({
            ...prevLoader,
            template : false
        }));
    }


    // Filter out fields from availableFields that already exist in formFields
    const filterAvailableFields = (formFieldsList) => {
        setAvailableFields((prevAvailableFields) =>
            prevAvailableFields.filter((field) => !formFieldsList.some((f) => f.id === field.id))
        );
    }

    // Function to add a field to sign in form
    const addFieldToSignInForm = (field) => {
        try {
            const newField = {
                id        : field.name,
                name      : field.name,
                label     : field.name,
                required  : "false",
                default   : field.default,
                type      : field.type
            }

            // Add dropdownOptions Property if field type is dropdown
            if(field.type == 'dropdown') {
                newField["dropdownOptions"] = field.dropdownOptions;
            }

            const fieldList = [
                ...formFields,
                newField
            ];

            setFormFields(fieldList);

            filterAvailableFields(fieldList);

            // When field is added to sign in form, scroll to bottom of the sign in form card for better UX
            if (formRef.current) {
                scrollIntoView(formRef.current, {
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'start'
                });
            }
        } catch(err) {
            showErrorToast('Something Went Wrong');
        }
        
    }

    // Remove a field from sign in form when remove button is pressed except for employees
    const removeFieldFromSignInForm = (field) => {
        const newForm = formFields.filter((item) => item.id !== field.id);

        // When field is removed, add it in available field
        const newAvailableFields = [
            ...availableFields,
            field
        ];
    
        setFormFields(newForm);
        setAvailableFields(newAvailableFields)
    }

    const enableEmployees = () => {
        setEmployees({
            ...employees,
            enabled : "true"
        })
    }


    // Function Calling Add/Update Template API
    const saveChanges = async () => {
        try {

            // Turning Loader Active
            setLoader({
                ...loader,
                saving : true
            });

            await form.validateFields();

            const confirmToSave = confirmTenantsChange();

            if(confirmToSave) {
                if(!selectedTemplate.default_template) {
                    const updated = await addOrUpdateTemplate();

                    if(updated) {
                        closeModalAfterSuccess();
                    }
                } else {
                    closeModalAfterSuccess();
                }
                
            }

            

        } catch(err) {
            console.error('Validation Errors');
            if (modalBodyRef.current) {
                scrollIntoView(modalBodyRef.current, {
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'start'
                });
            }
        } finally {
            // Turning Loader Disabled
            setLoader({
                ...loader,
                saving : false
            });
        }
        
    }


    // Fucntion to check if template assignment is changed for any tenants
    const confirmTenantsChange = () => {
        console.log(assignedTenantsList, checkedTenants);

        let tenantsAssignmentObj = {
            defaultTemplateAssignment : [],
            newAssignment : {
                template_id : selectedTemplate.template_id,
                tenants : []
            }
        };

        assignedTenantsList.forEach((tenant) => {
            // First checking if the tenant is currently assigned with the selected template
            if(tenant.template_id === selectedTemplate.template_id) {
                // If the tenant is unchecked for the selected template, then it means the tenant should now assign with default template
                if(!checkedTenants.includes(tenant.value)) {
                    tenantsAssignmentObj.defaultTemplateAssignment.push(tenant.value);
                }
            // For the tenants who are currently assigned with different template
            } else {
                // If the tenant is checked, then it means this tenant should assign with the selected template
                if(checkedTenants.includes(tenant.value)) {
                    tenantsAssignmentObj.newAssignment.tenants.push(tenant.value)
                }
            }
        });

        console.log(tenantsAssignmentObj);
        setTenantsAssignment(tenantsAssignmentObj);

        if(tenantsAssignmentObj.defaultTemplateAssignment.length > 0 || tenantsAssignmentObj.newAssignment.tenants.length > 0) {
            setConfirmChangesModal(true);
            return false;
        } else return true;

    }


    const addOrUpdateTemplate = async () => {
        let templateObj = {
            name : templateName,
            form : {
                employees       : employees,
                fields          : formFields.length > 0 ? formFields : ' ',
                visitorPhoto    : {
                    'required'  : visitorPhoto ? 'true' : 'false'
                }
            }
        }
        let path = ``;
        if(mode == 'edit') {
            path = `form-templates/${selectedTemplate.template_id}`;
        } else if(mode === 'add') {
            path = templateType === 'tenant' ? `tenants/${selectedTenant}/form-templates` : `buildings/${id}/form-templates`;
        }

        try {
            await post(path, templateObj);

            return true;

        } catch(err) {
            if (modalBodyRef.current) {
                scrollIntoView(modalBodyRef.current, {
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'start'
                });
            }
            if(err.response.status === 422) {
                
                if(err.response.data.message === 'template-name-exists') {
                    form.setFields([
                        {
                          name: 'templateName',
                          errors: ['Template name already exists. Try another name.'],
                        },
                      ]);
                      
                    return;
                }
            } else {
                showErrorToast('Something Went Wrong');
            }

            return false;
        }
    }

    const onConfirmChanges = async () => {
        try {
            // Turning Loader Enabled
            setLoader({
                ...loader,
                saving : true
            });

            setConfirmChangesModal(false);

            if(!selectedTemplate.default_template) {
                await addOrUpdateTemplate();
            }
            
            const assigned = await assignTenantTemplates();
            if(assigned) {
                closeModalAfterSuccess();
            } else {
                throw 'template-assignment-error';
            }
              
        } catch(err) {
            console.error('Something Went Wrong');
        } finally{
            // Turning Loader Disabled
            setLoader({
                ...loader,
                saving : false
            });
        }
        
    }

    const assignTenantTemplates = async () => {
        try{
            const response = await post(`buildings/${id}/form-templates/assign-to-tenants`, tenantsAssignment);
            console.log(response);
            return true;
        } catch(err) {
            console.error(err);
            return false;
        }
        
    }

    // Function that check if there are any unsaved changes before closing the modal
    const closeTemplateModal = () => {
        if(!selectedTemplate.default_template) {
            if (hasStateChanged()) {
                // If changes detected, ask for confirmation before closing
                const confirmClose = window.confirm("You have unsaved changes. Are you sure you want to close the modal without saving changes?");
                if (!confirmClose) return;
            }
        }

        closeModal();
    }


    // Function to close Template Modal after successful changes
    const closeModalAfterSuccess = () => {
        // Calling templates API to get latest data when modal is closed
        fetchTemplates();
        showSuccessToast(`Tempate ${mode === 'add' ? 'Added' : 'Updated'} Successfully`);
        closeModal();
    }

    // Function to check if state values have changed
    const hasStateChanged = () => {
        // Check employees object for changes
        const employeesChanged = !_.isEqual(employees, employeesMock);

        // Check formFields array for changes (deep comparison)
        const formFieldsChanged = !_.isEqual(formFields, formFieldsMock);

        // Check visitorPhoto boolean for changes
        const visitorPhotoChanged = visitorPhoto !== visitorPhotoMock;

        // If any of the states have changed, return true
        return employeesChanged || formFieldsChanged || visitorPhotoChanged;
    };


    return {
        availableFields, setAvailableFields,
        formFields, setFormFields,
        addFieldToSignInForm,
        employees, setEmployees,
        visitorPhoto, setVisitorPhoto,
        templateName, setTemplateName,
        removeFieldFromSignInForm,
        tenantsList,
        selectedTenant, setSelectedTenant,
        enableEmployees,
        loader,
        closeTemplateModal,
        saveChanges,
        tenantsAssignment,
        assignedTenantsList, setAssignedTenantsList,
        checkedTenants, setCheckedTenants,
        confirmChangesModal, setConfirmChangesModal,
        onConfirmChanges
    }
}

export default useTemplateModal;