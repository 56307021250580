import { useState } from "react"
import useApi from "hooks/useApi";
import { showErrorToast } from "functions/toasts";
import { useAuth } from "contexts/authContext";

const useReturningVisitorModal = (setSearchedVisitor, closeReturningVisitorModal) => {

    const [searchValue, setSearchValue] = useState('');
    const [visitor, setVisitor] = useState();
    const [loader, setLoader] = useState(false);

    const {get} = useApi();

    const { currentBuilding } = useAuth();

    // Function to search visitor from previous records
    const searchVisitor = async (e) => {
        e.preventDefault();
        setLoader(true);
        try {
            console.log(searchValue);
            const response = await get(`previous-visitors/${currentBuilding.attributes.building_id}?search=${searchValue}`);
            if(response?.data?.data) {
                const data = response.data.data;
                const visitorData = {
                    name                : data.name,
                    phoneNumber         : data.phone_number,
                    emailAddress        : data.email_address,
                    noOfVisits          : data.visits,
                    lastVisit           : data.last_visit,
                    lastTenantVisited   : data.last_tenant_visited,
                    fields              : JSON.parse(data.fields)
                }

                setVisitor({
                    status : true,
                    data : visitorData
                });
            } 
                
        } catch(err) {
            if(err.response.status === 404) {
                console.log('Visitor Not Found');
            } else {
                showErrorToast('Something Went Wrong');
            }
            setVisitor({
                status : false,
            });
        } finally {
            setLoader(false);
        }   
    }

    // Function to capture the fields to the sign in form when Sign In button is clicked
    const signInClick = () => {
        setSearchValue('');
        setSearchedVisitor(visitor);
        closeReturningVisitorModal();
    }

    return {
        searchValue, setSearchValue,
        visitor,
        loader,
        searchVisitor,
        signInClick
    }
}

export default useReturningVisitorModal;