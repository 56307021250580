import { Button, Tag, Input, Popconfirm, Select, Card, Table, Transfer, Skeleton, message } from 'antd';
import { Checkbox } from 'antd';
import {EditOutlined, SearchOutlined} from '@ant-design/icons';
import AssignedTenants from './assignedTenants/assignedTenants';
import Icon                                         from "components/icon/icon"
import { FaCheck } from "react-icons/fa6";
import {RxHamburgerMenu}                            from 'react-icons/rx';
import { FIELD_TYPE_TAG_COLORS, FIELD_TYPE_TAG_LABELS } from './constants';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import useTemplateModal from '../templateModal';
import useSignInForm from './useSignInForm';
import FieldModal from 'components/modal/fieldModal/fieldModal';


const SignInForm = ({
    selectedTemplate, 
    mode,
    templateType, 
    tenantId,
    availableFields, 
    setAvailableFields, 
    formFields, 
    setFormFields, 
    enableEmployees,
    employees, 
    setEmployees,
    removeFieldFromSignInForm,
    visitorPhoto, setVisitorPhoto, 
    formRef,
    addFieldToSignInForm,
    assignedTenantsList, setAssignedTenantsList,
    checkedTenants, setCheckedTenants,
    loader
}) => {

    const [messageApi, contextHolder] = message.useMessage();

    const {
        onDragEnd,
        logFormFields,
        fieldModal,
        openFieldModal,
        closeFieldModal,
        updateFieldProperties,
        disableEmployees,
        addNewField,
        selectedField,
        onEditFieldClicked,
        addField,
        addNewFieldToAvailableFields,
        employeeEdit,
        onCreateFieldClick,
        searchValue, setSearchValue,
        fieldModalMode,

    } = useSignInForm({
        selectedTemplate, 
        mode, 
        availableFields, 
        setAvailableFields, 
        formFields, 
        setFormFields, 
        employees, 
        setEmployees, 
        addFieldToSignInForm,
        messageApi
    });


  return (
    <>
        {contextHolder}
        <DragDropContext onDragEnd={onDragEnd}>
            <div className='flex gap-10 items-start'>
                <div className='max-w-[40%] w-[40%] sticky'>
                    {/* Available Fields Section */}
                    <Droppable droppableId='availableFields'>
                        {(provided) => (
                            <div className='sticky-container'>
                        <Card className='sticky'>
                            <div className='flex items-center mb-5 justify-between'>
                                <h4 className='font-medium text-xl'>Available Fields</h4>
                                <Button disabled={selectedTemplate.default_template} type='primary' onClick={addNewField}>+ Add</Button>
                            </div>
                            <Input 
                                placeholder='Search field name'
                                value={searchValue}
                                allowClear
                                onChange={(e) => setSearchValue(e.target.value)}
                                suffix={
                                    <SearchOutlined className='text-slate-700' />
                                } 
                            />
                            {!loader.fields ?
                            <div className='max-h-[50vh] custom-scrollbar pr-2 overflow-y-scroll flex mt-5 flex-col gap-2' ref={provided.innerRef} {...provided.droppableProps}>
                            {availableFields?.length === 0 ? (
                                <p>All available fields are added.</p>
                            ) : (
                                availableFields?.filter((field) => field.name.toLowerCase().includes(searchValue.toLowerCase())).length === 0 ? (
                                    <p>This field is not available to add.</p>
                                ) : (
                                availableFields?.filter((field) => field.name.toLowerCase().includes(searchValue.toLowerCase())).map((field, index) => (
                                <Draggable isDragDisabled={selectedTemplate.default_template ? true : false} key={field.name} draggableId={field.name} index={index}>
                                    {(provided) => (
                                    <div
                                        className={`p-3 border-[1px] flex items-center justify-between border-slate-200 bg-slate-50 rounded-md hover:border-primary group`}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                    >   
                                        <div className='max-w-[80%] font-medium text-slate-800'>
                                            {field.name}
                                        </div>
                                        {!selectedTemplate.default_template ?
                                        <div className='cursor-pointer text-slate-300 group-hover:font-medium group-hover:text-primary' onClick={() => {addFieldToSignInForm(field); setSearchValue('')}}>
                                            Add
                                        </div> : '' }
                                    </div>
                                    )}
                                </Draggable>
                                )))
                            )}
                            {provided.placeholder}
                            </div> : <Skeleton className='mt-10' /> }
                        </Card>
                        </div>
                        )}
                    </Droppable>
                </div>
                <div className='w-full'>
                    {/* Sign In Form Fields Section */}
                    <Droppable droppableId='formFields'>
                        {(provided) => (
                        <Card className='flex-1'>
                            <h4 className='font-bold text-slate-600 text-xl'>Sign In Form Fields</h4>
                            <p className='text-[1rem] mt-1 mb-5 text-slate-600'>Add fields to this template from the available fields. Please note that Visitor Name and Company Visiting is enabled by default and cannot be changed. Optionally you can enable or disable employees options from the template.</p>
                            {!loader.fields ?
                            <div className=' flex flex-col gap-2' ref={provided.innerRef} {...provided.droppableProps}>
                                <div className='bg-slate-50 cursor-not-allowed p-3 rounded-md flex justify-between items-center'>
                                    <p className='font-semibold text-slate-600'>Visitor Name</p>
                                </div>
                                <div className='bg-slate-50 cursor-not-allowed p-3 rounded-md flex justify-between items-center'>
                                    <p className='font-semibold text-slate-600'>Company Visiting</p>
                                </div>
                                {employees.enabled == 'false' ?
                                <div className='bg-slate-100 mb-10 p-3 rounded-md flex justify-between items-center'>
                                    <p className='font-semibold text-slate-400'>Employees</p>
                                    <Button size='small' type='primary' disabled={selectedTemplate.default_template} onClick={enableEmployees}>Enable</Button>
                                </div> :
                                <div className='bg-white border-[1px] mb-10 border-slate-200 hover:border-primary p-3 rounded-md'>
                                    <div className='flex justify-between'>
                                        <p className='font-semibold text-slate-700'>Employees</p>
                                        <div className='flex gap-2'>
                                            <Button className='text-slate-400' size='small' onClick={employeeEdit} disabled={selectedTemplate.default_template} icon={<EditOutlined />}>Edit</Button>
                                            <Popconfirm
                                                title="Remove field"
                                                description="Are you sure to disable employees from template?"
                                                onConfirm={disableEmployees}
                                                // onCancel={cancel}
                                                okText="Yes"
                                                cancelText="No"
                                            >
                                                <Button size='small' disabled={selectedTemplate.default_template} danger>Disable</Button>
                                            </Popconfirm>
                                        </div>
                                    </div>
                                    <div className='flex items-center mt-5'>
                                        <Tag color={employees.required == "true" ? 'red' : 'green'}>{employees.required == "true" ? 'Required' : 'Not Required'}</Tag>
                                        {/* <Tag color={FIELD_TYPE_TAG_COLORS[field.type]}>{FIELD_TYPE_TAG_LABELS[field.type]}</Tag> */}
                                        <Tag>{employees.label}</Tag>
                                    </div>
                                
                                </div> }
                                
                                {formFields?.map((field, index) => (
                                <Draggable key={field.id} isDragDisabled={selectedTemplate.default_template ? true : false} draggableId={field.id} index={index}>
                                    {(provided) => (
                                    <div
                                        className='bg-white border-[1px] border-slate-200 hover:border-primary p-3 rounded-md'
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                    >
                                        <div className='flex justify-between'>
                                            <p className='font-semibold text-slate-700'>{field.name}</p>
                                            <div className='flex gap-2'>
                                                <Button className='text-slate-400' size='small' disabled={selectedTemplate.default_template} onClick={() => onEditFieldClicked(field)} icon={<EditOutlined />}>Edit</Button>
                                                <Popconfirm
                                                    title="Remove field"
                                                    description="Are you sure to remove this field from template?"
                                                    // onConfirm={confirm}
                                                    // onCancel={cancel}
                                                    okText="Yes"
                                                    cancelText="No"
                                                >
                                                    <Button size='small' disabled={selectedTemplate.default_template} danger onClick={() => removeFieldFromSignInForm(field)}>Remove</Button>
                                                </Popconfirm>
                                            </div>
                                        </div>
                                        <div className='flex items-center mt-5'>
                                            <Tag color={field.required == "true" ? 'red' : 'green'}>{field.required == "true" ? 'Required' : 'Not Required'}</Tag>
                                            <Tag color={FIELD_TYPE_TAG_COLORS[field.type]}>{FIELD_TYPE_TAG_LABELS[field.type]}</Tag>
                                            <Tag>{field.label}</Tag>
                                        </div>
                                        
                                    </div>
                                    )}
                                </Draggable>
                                ))}
                                <p className='text-center text-[1rem] mt-5 text-slate-600 italic'>Drag and drop, or add fields from available fields</p>
                            {provided.placeholder}
                            </div> : <Skeleton className='mt-10' /> }
                        </Card>
                        )}
                    </Droppable>

                    {!loader.fields ?
                    <Card className='mt-5'>
                        <Checkbox disabled={selectedTemplate.default_template} checked={visitorPhoto} onChange={(e) => setVisitorPhoto(e.target.checked)}>Visitor Photo Required</Checkbox>
                    </Card> : <Skeleton className='mt-5' title={false} paragraph={{ rows : 2 }} /> }
                    
                    {mode == 'edit' ? 
                        !loader.tenants ?
                        <AssignedTenants 
                            selectedTemplate={selectedTemplate}
                            mode={mode}
                            templateType={templateType}
                            tenantId={tenantId}
                            assignedTenantsList={assignedTenantsList}
                            setAssignedTenantsList={setAssignedTenantsList}
                            checkedTenants={checkedTenants}
                            setCheckedTenants={setCheckedTenants}
                        /> : <Skeleton className='mt-10' title={false} paragraph={{ rows : 2 }} /> 
                    : ''}

                    <div ref={formRef} className='pb-[100px]'></div>
                </div>
            </div>
        </DragDropContext>
        
        {fieldModal &&
        <FieldModal 
            isModalOpen={fieldModal}
            closeModal={closeFieldModal}
            addNewFieldToAvailableFields={addNewFieldToAvailableFields}
            updateFieldProperties={updateFieldProperties}
            fieldModalMode={fieldModalMode}
            addField={addField}  
            selectedField={selectedField}
            availableFields={availableFields}          
        />}
    </>
  )
}

export default SignInForm